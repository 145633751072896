import React, { useState, useEffect, useContext } from "react";
import { useParams, Link } from "react-router-dom";
import {
  Container,
  Form,
  Button,
  Alert,
  Spinner,
  Badge,
  CloseButton,
  Modal,
  FormControl,
  ListGroup,
  Accordion,
} from "react-bootstrap";
import { QuillEditor } from "../../QuillEditor/QuillEditor"; // Import the QuillEditor component
import axios from "axios";
import { Helmet } from "react-helmet";
import "./EditInReviewBlog.css";
import { useNavigate } from "react-router-dom";
import blogCategory from "../../../utils/blogCategory.json";
import blogTags from "../../../utils/blogTags.json";
import TinymceEditor from "../../../utils/TinymceEditor";
import FileUpload from "utils/FileUpload";

import { AuthContext } from "contexts/AuthContext";
import PreLoader from "utils/PreLoader";
import { toast, ToastContainer } from "react-toastify";


const EditInReviewBlog = () => {
  const { user, loading, setLoading, logout } = useContext(AuthContext);
  const { id } = useParams();
  const [title, setTitle] = useState("");
  const [authorDetails, setAuthorDetails] = useState(null);
  const [content, setContent] = useState("");
  const [category, setCategory] = useState("");
  const [slug, setSlug] = useState("");
  const [reviewedBy, setReviewedBy] = useState("");
  const [lastUpdatedAt, setLastUpdatedAt] = useState("");
  const [alert, setAlert] = useState(null);
  const [showSaveBlogModal, setShowSaveBlogModal] = useState(false);

  const [otherCategory, setOtherCategory] = useState(null);
  const [selectedTag, setSelectedTag] = useState("");
  const [tags, setTags] = useState([]);
  const [contentSize, setContentSize] = useState(0);
  const [rating, setRating] = useState(0);
  const [reviewRemarks, setReviewRemarks] = useState("");

  const [showPreviewBlogModal, setShowPreviewBlogModal] = useState(false);
  const [searchTitleResults, setTitleSearchResults] = useState([]);
  const [initialContent, setInitialContent]= useState("");
  const [isDisabled, setIsDisabled]= useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchBlog = async () => {
      try {
        const response = await axios.get(`/api/admin/blog/editblog/${id}?userId=${user._id}&role=${user.role}`);
        const {
          slug,
          title,
          content,
          authorDetails,
          category,
          reviewedBy,
          lastUpdatedAt,
          tags,
        } = response.data;
        setSlug(slug);
        setTitle(title);
        setAuthorDetails(authorDetails);
        setContent(content);
        setInitialContent(content);
        setCategory(category);
        setReviewedBy(reviewedBy);
        setLastUpdatedAt(lastUpdatedAt);
        setTags(tags);
        // setBlog(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching Blog:", error);
      }
    };

    fetchBlog();
  }, [id, user]);

  //  Size of the content
  useEffect(() => {
    const findContentSize = (content) => {
      setContentSize(
        (new TextEncoder().encode(content).length / 1024).toFixed(4)
      );
    };
    findContentSize(content);
  }, [content]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsDisabled(true);

    if (rating === 0 || reviewRemarks === "") {
      toast.warning("Please give the rating and remarks.")
      setIsDisabled(false);
      return;
    }

    try {
      const response = await axios.put(`/api/admin/blog/publish/${id}?userId=${user._id}&role=${user.role}&email=${user.email}`, {
        slug,
        title,
        content,
        category: category === "Other" ? otherCategory : category,
        rating: rating,
        reviewRemarks,
        tags,
      });
      console.log(response.data);
  
      toast.success("Blog updated!!");
      // Redirect to the homepage
      setTimeout(() => {
        navigate("/");
      }, 2000);
    } catch (error) {
      console.error("Error updating blog:", error);
      toast.error("Failed to update blog: ", error);
      setIsDisabled(false);
    }
  };

  const handleSelectedTag = (e) => {
    setSelectedTag(e.target.value);
  };

  const handleTagDismiss = (tag) => {
    const updatedTags = tags.filter((t) => t !== tag);
    setTags(updatedTags);
  };

  const handleTagAdd = () => {
    if (selectedTag !== "" && !tags.includes(selectedTag)) {
      setTags([...tags, selectedTag]);
      setSelectedTag("");
    }
  };

  if (loading) {
    return (
      <PreLoader isLoading={loading} />
    );
  }

  const slugify = (title) => {
    return title
      .toLowerCase()
      .replace(/[^a-zA-Z0-9 ]/g, "")
      .replace(/\s+/g, "-");
  };

  const handleshowSaveBlogModal = () => {
    setShowSaveBlogModal(true); // Show the confirmation modal
  };

  const handleRatingClick = (value) => {
    setRating(value);
  };

  async function searchSimilarTitles(searchQuery) {
    try {
      setLoading(true);
      const response = await axios.get(`/api/blogs/searchblogs/${searchQuery}`);
      console.log(response.data);
      setTitleSearchResults(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error searching similar blogs:", error);
      setLoading(false);
    }
  }

  return (
    <section className="newpage-section">
      <Helmet>
        <title>Edit In Review Blog - BloggerSpace Reviewer Panel</title>
      </Helmet>
      <Container>
        <h2 className="page-title">Edit Blog</h2>
        <div className="heading-underline"></div>
        <ToastContainer />
        <p>
          <i>Last updated: {lastUpdatedAt?.slice(0, 19)}</i>
        </p>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="blogTitle" className="editblogfields">
            <Form.Label>Title: </Form.Label>
            <Form.Control
              type="text"
              value={title}
              onChange={(e) => {
                setTitle(e.target.value);
                setSlug(slugify(e.target.value));
                searchSimilarTitles(e.target.value);
              }}
              placeholder="Enter blog title"
            />
          </Form.Group>

          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                Published Blogs with Similar title:
              </Accordion.Header>
              <Accordion.Body>
                <ListGroup>
                  {searchTitleResults.map((blog) => (
                    <ListGroup.Item key={blog._id} className="">
                      <Link
                        to={`/${blog.slug}`}
                        target="_blank"
                        style={{ textDecoration: "none" }}
                      >
                        <h6>{blog.title}</h6>
                      </Link>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>

          <Form.Group controlId="blogAuthor" className="editblogfields">
            <Form.Label>Author:</Form.Label>
            <Form.Control
              type="text"
              value={authorDetails?.email}
              // onChange={(e) => setAuthor(e.target.value)}
              placeholder="Enter author name"
              disabled
            />
          </Form.Group>

          <Form.Group controlId="blogCategory" className="editblogfields">
            <Form.Label>Category:</Form.Label>
            <Form.Control
              as="select"
              value={category}
              onChange={(e) => setCategory(e.target.value)}
              placeholder="Select category"
              required
            >
              <option value="">Select Category</option>
              {blogCategory.map((category) => (
                <option key={category.value} value={category.value}>
                  {category.label}
                </option>
              ))}
              {/* Add more category options as needed */}
            </Form.Control>
          </Form.Group>

          {category === "Other" ? (
            <Form.Group controlId="otherCategory" className="newblogfields">
              <Form.Label>Specify other category:</Form.Label>
              <Form.Control
                type="text"
                value={otherCategory}
                onChange={(e) => {
                  setOtherCategory(e.target.value);
                }}
                placeholder="Enter category"
                required
              />
            </Form.Group>
          ) : null}

          <Form.Group controlId="blogCategory" className="newblogfields">
            <Form.Label>Tags:</Form.Label>
            <Form.Control
              as="select"
              value={selectedTag}
              onChange={handleSelectedTag}
              placeholder="Select tag"
            >
              <option value="">Select Tag</option>
              {blogTags.map((tag) => (
                <option key={tag} value={tag}>
                  {tag}
                </option>
              ))}
            </Form.Control>
            <Button
              variant="success"
              size="sm"
              onClick={handleTagAdd}
              className="my-2"
            >
              Add Tag
            </Button>
            <br />
            {tags.map((tag) => (
              <Badge key={tag} pill bg="secondary">
                {tag}
                <CloseButton
                  variant="white"
                  onClick={() => handleTagDismiss(tag)}
                ></CloseButton>
              </Badge>
            ))}
          </Form.Group>

          <Form.Group controlId="blogContent" className="editblogfields">
            <Form.Label>Content:</Form.Label>
            {/* <QuillEditor content={content} onContentChange={setContent} /> */}
            <TinymceEditor content={content} onContentChange={setContent} initialValue={initialContent} />
          </Form.Group>
          <h6>Content size: {contentSize} KB</h6>

          <b>Reviewed By: </b>
          {reviewedBy.length!==0 && reviewedBy.map((reviewer, index) => (
            <div>
              <i>
                {++index}. {reviewer.ReviewedBy.Email}:{" "}
              </i>
              {reviewer.Rating !== null ? (
                <b className={`star ${reviewer.Rating ? "filled" : ""}`}>
                  {reviewer.Rating} &#9733;
                </b>
              ) : (
                "null"
              )}
            </div>
          ))}

          {/* <Button variant="success" type="submit" className="submit-editedblog">
          Publish
        </Button> */}


          <br />
          <Button
            variant="secondary"
            className="submit-editedblog"
            onClick={() => setShowPreviewBlogModal(true)}
          >
            Preview Blog
          </Button>

          <Button
            variant="success"
            onClick={handleshowSaveBlogModal}
            className="submit-editedblog"
            disabled={isDisabled}
          >
            Publish
          </Button>

          <Button
            variant="primary"
            onClick={() => navigate(-1)}
            className="goback-editedblog"
          >
            Go Back
          </Button>
        </Form>

        <FileUpload />

        <Modal
          show={showPreviewBlogModal}
          onHide={() => setShowPreviewBlogModal(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>Preview Blog</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Title: {title}
            <br />
            Slug: {slug}
            <br />
            <br />
            Content: <br />
            <div dangerouslySetInnerHTML={{ __html: content }} />
            <h6>Content size: {contentSize} KB</h6>
          </Modal.Body>

          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setShowPreviewBlogModal(false)}
            >
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={showSaveBlogModal}
          onHide={() => setShowSaveBlogModal(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>Move to In-Review</Modal.Title>
          </Modal.Header>
          {alert && (
            <Alert
              variant={alert.type}
              onClose={() => setAlert(null)}
              dismissible
            >
              {alert.message}
            </Alert>
          )}
          <Modal.Body>
            <h6>Rating:</h6>
            <div className="star-container">
              {[1, 2, 3, 4, 5].map((value) => (
                <span
                  key={value}
                  className={`star ${value <= rating ? "filled" : ""}`}
                  onClick={() => handleRatingClick(value)}
                >
                  &#9733;
                </span>
              ))}
            </div>
            <FormControl
              type="text"
              placeholder="Remarks"
              value={reviewRemarks}
              onChange={(e) => setReviewRemarks(e.target.value)}
              required
            />
          </Modal.Body>

          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setShowSaveBlogModal(false)}
            >
              Cancel
            </Button>
            <Button variant="primary" onClick={handleSubmit} disabled={isDisabled}>
              Publish
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>
    </section>
  );
};

export default EditInReviewBlog;
