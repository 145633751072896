import React, { useState, useEffect, useContext } from "react";
import { Container, Card, Spinner,Form,FormControl, Button, ListGroup, Row, Col } from "react-bootstrap";
// import axios from "axios";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import './AdminDashboard.css';
import { AuthContext } from "contexts/AuthContext";
import PreLoader from "utils/PreLoader";

const AdminDashboard = () => {
    const { user, loading, logout } = useContext(AuthContext);
  const navigate = useNavigate();
  const location= useLocation();

  const [selectedValue, setSelectedValue]= useState("");

  const handleSelectedChoice = (e) => {
    setSelectedValue(e.target.value);
    return navigate(e.target.value);
  };

  const updateSitemapFile= async()=>{
    try {
      toast.info("Updating sitemap. Please wait 1-2 mins...");
      const res= await axios.get(`/api/admin/updatesitemapxml?userId=${user._id}&role=${user.role}`);
      toast.success("Sitemap file updated successfully!!");
    } catch (error) {
      toast.error("Error updating sitemap file");
      console.log("Error updating sitemap file");
    }
  }

  

  return (
    <section className="newpage-section">
      <Helmet>
        <title>Admin Dashboard - BloggerSpace Reviewer Panel</title>
      </Helmet>
      <Container>
      <h3 className="page-title">Admin dashboard</h3>
      <div className="heading-underline"></div>

      <Form.Group controlId="blogCategory" className="newblogfields">
        <Form.Label>Please select from the dropdown:</Form.Label>
        <Form.Control
          as="select"
          value={selectedValue}
          onChange={handleSelectedChoice}
          placeholder="Select"
        >
          <option value="">Select</option>
          <option value="allusers">Users</option>
          <option value="allreviewers">Reviewers</option>
          <option value="community">Community</option>
          <option value="reports">Download Report</option>
          {/* <option value="allblogs">Blogs</option> */}
        </Form.Control>
        {/* <Button
          variant="success"
          //   size="sm"
          onClick={()=>navigate("/")}
          className="my-2"
        >
          Go
        </Button> */}
      </Form.Group>

      <br />
      <h4>Sitemap:</h4>
      <Row>
        <Col>
            Update Sitemap file 
        </Col>
        <Col>
            <Button className="bs-button" size="sm" onClick={updateSitemapFile}>Update</Button>
        </Col>
        <Col>
            <Link to={`${process.env.REACT_APP_BLOGGERSPACE}/api/sitemap.xml`} target="_blank">View Sitemap</Link>
        </Col>
      </Row>

    </Container>
    </section>
  );
}

export default AdminDashboard;
