import React, { useState, useEffect, useContext } from "react";
import {
  Container,
  Card,
  Button,
  Alert,
  Spinner,
  ListGroup,
  Modal,
  FormControl,
} from "react-bootstrap";
import axios from "axios";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";
import ImageCompressor from "image-compressor.js";
import "./MyProfilePage.css";

import { AuthContext } from "contexts/AuthContext";
import PreLoader from "utils/PreLoader";
import { toast, ToastContainer } from "react-toastify";


const MyProfilePage = () => {
  const { user, loading, setUser, logout } = useContext(AuthContext);
  const [selectedFile, setSelectedFile] = useState(null);
  const navigate = useNavigate();

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [fullName, setFullName] = useState(user?.fullName);
  const [userName, setUserName] = useState(user?.userName);
  const [userNameAvailable, setUserNameAvailable] = useState(null);
  const [isDisabled, setIsDisabled]= useState(false);

  const handleFileChange = async (e) => {
    // setSelectedFile(e.target.files[0]);

    const file = e.target.files[0];
    // Check if the file size is greater than 64KB
    if (file.size > 0.064 * 1024 * 1024) {
      try {
        // Compress the image to a maximum of 64KB
        const compressedFile = await new ImageCompressor().compress(file, {
          quality: 0.6, // Adjust the quality as per your preference
          maxWidth: 800, // Adjust the maximum width as per your preference
          maxHeight: 800, // Adjust the maximum height as per your preference
          maxSizeMB: 0.064, // Maximum size in MB (64KB)
        });

        console.log(
          "Compressed Image Size: ",
          compressedFile.size / 1024 + " KB"
        );
        setSelectedFile(compressedFile);
      } catch (error) {
        console.error("Error compressing image:", error);
        // Handle the error
      }
    } else {
      console.log("Image Size: ", file.size / 1024 + " KB");
      setSelectedFile(file);
    }
  };

  const handleUpload = () => {
    if (selectedFile) {
      const formData = new FormData();
      formData.append("profilePicture", selectedFile);
      setIsDisabled(true);

      axios
        .post(`/api/reviewer/uploaduserprofilepicture?userId=${user._id}&role=${user.role}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log(response.data);
          toast.success("Profile picture uploaded!");
          setUser(prevUser=>({
            ...prevUser, profilePicture: response.data.newPicture
          }))
          setSelectedFile(null);
          setIsDisabled(false);

          // Reload the current page
          // window.location.reload();
        })
        .catch((error) => {
          console.error("Error uploading profile picture:", error);
          toast.error("Failed to upload profile picture");
          setIsDisabled(false);
        });
    }
  };

  if (loading) {
    return (
      <PreLoader isLoading={loading} />
    );
  }

  const checkUserNameAvailable = async (userName) => {
    await axios
      .post("/api/users/checkusername", { userName })
      .then((response) => {
        const userData = response.data.message;

        console.log(userData);
        setUserNameAvailable(true);
      })
      .catch((error) => {
        setUserNameAvailable(false);
        console.log("Error: " + error.response.data.message);
        // console.error("Error fetching user information:", error.response.data.message);
      });
  };

  const handleshowEditPersonalDetailsModal = () => {
    setShowConfirmModal(true); // Show the confirmation modal
  };

  const updatePersonalDetails = async () => {
    try {
      const response = await axios.patch(`/api/reviewer/changeusername?userId=${user._id}&role=${user.role}`, {
        fullName,
        userName,
      });
      toast.success(response.data.message);
      setUserName("");
      setShowConfirmModal(false);
      setUser(prevUser=>({
        ...prevUser,
        userName: userName
      }));
    } catch (error) {
      toast.error(error.response.data.error)
    }
  };

  return (
    <section className="newpage-section">
      <Helmet>
        <title>My Profile - BloggerSpace Reviewer Panel</title>
      </Helmet>
      <Container>
        <h2 className="page-title">My Profile</h2>
        <div className="heading-underline"></div>
        <ToastContainer />
        <Card>
          <Card.Body>
           
            <div className="profile-section">
              <div className="profile-picture">
                {/* Display the user's profile picture */}
                {user && (
                  <>
                    {user.profilePicture ? (
                      <img
                        src={`data:image/jpeg;base64,${user.profilePicture}`}
                        alt="Profile"
                      />
                    ) : (
                      <img
                        src="https://img.freepik.com/free-icon/user_318-159711.jpg"
                        alt="Profile"
                      />
                    )}

                    {selectedFile ? (
                      <p className="file-name">{selectedFile.name}</p>
                    ) : (
                      <div className="file-input">
                        <label className="custom-file-upload">
                          <input
                            type="file"
                            accept="image/*"
                            onChange={handleFileChange}
                          />
                          Change Picture
                        </label>
                      </div>
                    )}
                    {selectedFile && (
                      <Button variant="success" onClick={handleUpload} disabled={isDisabled}>
                        {isDisabled?"Uploading...":"Upload"}
                      </Button>
                    )}
                  </>
                )}
              </div>
              <div className="profile-details">
                {user && (
                  <>
                    <p>
                      <strong>Name:</strong> {user.fullName}
                    </p>
                    <p>
                      <strong>Email:</strong> {user.email}
                    </p>
                    <p>
                      <strong>Username:</strong> {user.userName}
                    </p>

                    {/* <p>
                    <strong>Public Profile:</strong>{" "}
                    <Link to={`/profile/${user.userName}`} target="_blank">
                      View Profile
                    </Link>
                  </p> */}

                    <div className="verification-status">
                      <strong>Verification Status:</strong>{" "}
                      {user.isVerified ? "Verified" : "Not Verified"}
                    </div>
                  </>
                )}
              </div>
              <Button
                variant="primary"
                className="bs-button"
                onClick={handleshowEditPersonalDetailsModal}
              >
                Edit Personal Details
              </Button>
            </div>
          </Card.Body>
        </Card>

        <Modal
          show={showConfirmModal}
          onHide={() => setShowConfirmModal(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>Personal Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <b>Full Name:</b>
            <FormControl
              type="text"
              placeholder={user?.fullName}
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
            />

            <b>Username:</b>
            <FormControl
              type="text"
              placeholder={user?.userName}
              value={userName}
              onChange={(e) => {
                checkUserNameAvailable(e.target.value);
                setUserName(e.target.value);
              }}
            />
            {userNameAvailable !== null ? (
              userNameAvailable === true ? (
                <Alert variant="success">Username Available</Alert>
              ) : (
                <Alert variant="danger">Username not Available</Alert>
              )
            ) : null}
          </Modal.Body>

          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setShowConfirmModal(false)}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={updatePersonalDetails}
              disabled={!userNameAvailable}
            >
              Save Details
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>
    </section>
  );
};

export default MyProfilePage;
