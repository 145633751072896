import React, {useContext} from 'react';
import axios from 'axios';
import { Button, Container } from 'react-bootstrap';

import { AuthContext } from "contexts/AuthContext";
import PreLoader from "utils/PreLoader";

const DownloadReport = () => {
  const { user, loading, logout } = useContext(AuthContext);
  
    const handleDownloadExcel = () => {
        axios.get(`/api/admin/dashboard/downloadexcel?userId=${user._id}&role=${user.role}`, {
          responseType: 'blob', // important to handle binary data (Excel file)
        })
        .then(response => {
          // Create a Blob from the Excel data
          const url = window.URL.createObjectURL(new Blob([response.data]));
          
          // Create a temporary link element to trigger the file download
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'report.xlsx'); // Specify file name
          document.body.appendChild(link);
          link.click(); // Trigger the download
        })
        .catch(error => {
          console.error('Error generating Excel report', error);
        });
      };
      

      const handleDownloadPDF = () => {
        axios.get(`/api/admin/dashboard/downloadpdf?userId=${user._id}&role=${user.role}`, {
          responseType: 'blob', // important to handle binary data (PDF file)
        })
        .then(response => {
          // Create a Blob from the PDF data
          const url = window.URL.createObjectURL(new Blob([response.data]));
          
          // Create a temporary link element to trigger the file download
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'report.pdf'); // Specify file name
          document.body.appendChild(link);
          link.click(); // Trigger the download
        })
        .catch(error => {
          console.error('Error generating PDF report', error);
        });
      };
      
  return (
    <section className='newpage-section'>
        <Container>

      <h3 className='page-title'>Generate Reports</h3>
      <div className="heading-underline"></div>
      <Button onClick={handleDownloadExcel} className='bs-button mt-1'>Download Excel Report</Button> {" "}
      <Button onClick={handleDownloadPDF} className='bs-button mt-1'>Download PDF Report</Button>
        </Container>
    </section>
  );
};

export default DownloadReport;
