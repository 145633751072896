import React, { useState, useContext } from "react";
import { Form, Button, Alert, Container, Row, Col, FloatingLabel } from "react-bootstrap";
import "./ReviewerLoginScreen.css";

import { useNavigate, Link, useLocation } from "react-router-dom";
import axios from "axios";
import { Helmet } from "react-helmet";

import { AuthContext } from "contexts/AuthContext";
import PreLoader from "utils/PreLoader";
import { toast, ToastContainer } from "react-toastify";
import { CiLock } from "react-icons/ci";


function ReviewerLoginScreen() {
  const { user, loading, setUser, logout } = useContext(AuthContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [role, setRole] = useState("Reviewer");
  const [loginHeading, setLoginHeading] = useState("Reviewer");
  const [isDisabled, setIsDisabled]= useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const from = location.state?.from?.pathname || "/";

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleRoleChange = (e) => {
    setRole(e.target.value);
    setLoginHeading(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Add your login logic here
    setIsDisabled(true);

    // Create the request body
    const requestBody = {
      email: email,
      password: password,
    };

    if (role === "Reviewer") {
      console.log(role);
      // Send the login request to the backend
      axios
        .post("/api/reviewer/login", requestBody)
        .then(async (response) => {
          // Handle the login response here
          console.log(response.data.token);
          console.log(response.data.reviewerDetails);

          // Retrieve the token from the response
          const { token } = response.data;

          // Store the token in localStorage
          localStorage.setItem("currenttoken", token);
          localStorage.setItem("currentrole", "Reviewer");

          // Check if the login was successful
          if (response.status === 200) {
            toast.success("Login successful");
            
            setUser(response.data.reviewerDetails);

            const headers = {
              Authorization: `Bearer ${token}`,
            };
            console.log(headers);

            if (response.data.reviewerDetails.isVerified) {
              // await axios.get("/api/blogs", { headers });
              // Redirect to the homepage
              setTimeout(() => {
                navigate(from, { replace: true });
              }, 1500);
            } else {
              console.log("Error");
            }
          } else {
            toast.error("Login failed");
          }
        })
        .catch((error) => {
          // Handle any errors here
          console.error(error);
          toast.error(error.response.data.message);
          setIsDisabled(false);
        });
    } else {
      // Send the login request to the backend
      axios
        .post("/api/admin/login", requestBody)
        .then(async (response) => {
          // Handle the login response here
          console.log(response.data.token);
          console.log(response.data.adminDetails);

          // Retrieve the token from the response
          const { token } = response.data;

          // Store the token in localStorage
          localStorage.setItem("currenttoken", token);
          localStorage.setItem("currentrole", "Admin");

          // Check if the login was successful
          if (response.status === 200) {
            toast.success("Login successful");
            
            setUser(response.data.adminDetails);

            if (response.data.adminDetails.isVerified) {
              setTimeout(() => {
                navigate(from, { replace: true });
              }, 1000);
            } else {
              console.log("Error");
            }
          } else {
            toast.error("Login failed");
          }
        })
        .catch((error) => {
          // Handle any errors here
          console.error(error);
          toast.error(error.response.data.message);
          setIsDisabled(false);
        });
    }
  };

  return (
    <section className="newpage-section">
      <Helmet>
        <title>Login - BloggerSpace Reviewer Panel</title>
      </Helmet>
      <ToastContainer />
      <Container>
        <Row className="pt-3">
        <Col md={6}>
            {/* <img
              src="assets/signin.png"
              // src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR9bb9Mz7yTmUO-Ky9T9pTXHb2W5cUW9_L4FWcxCyGq5A&s"
              className="loginpage-image"
            /> */}
            <CiLock className="lock-icon" />
          </Col>
          <Col md={6}>
            <div className="login-form bgcolor-mint">
              <h2 className="text-center page-title">{loginHeading} Login</h2>
              <div className="heading-underline mx-auto"></div>
               
                <Form onSubmit={handleSubmit}>
                  <Form.Group controlId="email">
                  <FloatingLabel
                      controlId="floatingInput1"
                      label="Email address"
                      className="mb-3"
                      key="email"
                    >
                    <Form.Control
                      type="email"
                      placeholder="Enter email"
                      value={email}
                      onChange={handleEmailChange}
                      required
                    />
                    </FloatingLabel>
                  </Form.Group>

                  <Form.Group controlId="password">
                    <div className="password-input">
                    <FloatingLabel
                      controlId="floatingInput2"
                      label="Password"
                      className="mb-3"
                      key="password"
                    >
                      <Form.Control
                        type={showPassword ? "text" : "password"}
                        placeholder="Password"
                        value={password}
                        onChange={handlePasswordChange}
                        required
                      />
                      </FloatingLabel>
                      <i
                        className={`toggle-password fas ${
                          showPassword ? "fa-eye-slash" : "fa-eye"
                        }`}
                        onClick={togglePasswordVisibility}
                      ></i>
                    </div>
                  </Form.Group>

                  <Form.Group controlId="role">
                    {" "}
                    {/* Added form group for role */}
                    <FloatingLabel
                      controlId="floatingInput3"
                      label="Role"
                      className="mb-3"
                      key="role"
                    >
                    <Form.Control
                      as="select"
                      value={role}
                      onChange={handleRoleChange}
                    >
                      <option value="Reviewer">Reviewer</option>
                      <option value="Admin">Admin</option>
                    </Form.Control>
                    </FloatingLabel>
                  </Form.Group>

                  <Button
                    variant="primary"
                    type="submit"
                    className="mt-2 bs-button"
                    block
                    disabled={isDisabled}
                  >
                    {isDisabled?"Please wait...":"Login"}
                  </Button>
                  <Button
                    variant="secondary"
                    type="submit"
                    onClick={() => navigate("/forgotpassword")}
                    className="mt-2 mx-1 bs-button-outline"
                    block
                  >
                    Forgot password?
                  </Button>
                  <div>
                    Don't have an account? <Link to="/signup">Sign up</Link>
                  </div>
                </Form>
                </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default ReviewerLoginScreen;
